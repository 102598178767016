import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  Badge,
  Text,
  Box,
  Tooltip,
  IconButton,
  Button,
  SimpleGrid,
  Select,
  Input,
  SlideFade,
  useDisclosure,
  Divider,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';
import TableLoadingSkeleton from '../loading/TableLoadingSkeleton';
import useWindowSize from '../../helper/useWindowSize';
// import DataTable from '../common/DataTable';
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';

function ClubActivitiesTable({ title, max = 0 }) {
  const size = useWindowSize();
  let [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onToggle } = useDisclosure();
  const [filteredBy, setFilteredBy] = useState(2);
  const [showFilter, setShowFilter] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  let userActivities = [];
  const {
    clubActivities,
    loading,
    deleteActivities,
    getClubActivities,
    clubMembers,
    activities,
    activityTypes,
    filterClubActivities,
    getActivityTypes,
    activityCategories,
    getActivities,
    filterUserActivities,
    filterClubActivityList,
  } = useContext(UserContext);
  const [filter, setFilter] = useState({
    status_id: 2,
    user_id: searchParams.get('user_id') ?? '',
    club_id: searchParams.get('club_id') ?? '',
    club_branch_id: searchParams.get('club_branch_id') ?? '',
    club_state_id: searchParams.get('club_state_id') ?? '',
    activity_category_id: '',
    activity_type_id: '',
    activity_id: '',
    from_date: '',
    to_date: '',
  });
  const [defaultFilter, setDefaultFilter] = useState({
    status_id: 2,
    user_id: '',
    activity_category_id: '',
    activity_type_id: '',
    activity_id: '',
    from_date: '',
    to_date: '',
  });

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
    console.log('selectedRows', selectedRows);
    console.log('selectedRows', state.selectedRows);
  }, []);

  const handleDelete = () => {
    var selected = [];
    selectedRows.forEach(element => {
      selected.push(element.id);
    });

    console.log('selected', selected);
    deleteActivities(selected, 'club_activities');
  };

  const headers = [
    { label: 'Date', key: 'activity_date' },
    { label: 'Member', key: 'user_name' },
    { label: 'Category', key: 'category' },
    { label: 'Activity Type', key: 'activity_type_name' },
    { label: 'Activity Name', key: 'activity_name' },
    { label: 'Credits', key: 'credits' },
  ];

  const options = [
    {
      id: 2,
      name: 'Approved',
    },
    {
      id: 1,
      name: 'Pending',
    },
  ];

  useEffect(() => {
    if (filter.user_id != '') {
      filterUserActivities(filter.user_id);
    } else if (filter.club_id != '') {
      filterClubActivityList(filter.club_id, 'club_id', filter);
    } else if (filter.club_branch_id != '') {
      filterClubActivityList(filter.club_branch_id, 'club_branch_id', filter);
    } else if (filter.club_state_id != '') {
      filterClubActivityList(filter.club_state_id, 'club_state_id', filter);
    } else if (filter.activity_id != '') {
      filter.activity_type_id = '';
      filterUserActivities(filter.user_id);
    } else if (filter != defaultFilter) {
      filterClubActivities(filter);
    } else {
      getClubActivities(2);
    }
  }, [filter]);

  const tableColumns = [
    {
      name: 'Date',
      selector: row => row.activity_date,
      sortable: true,
      format: (row, index) => {
        return moment(row.activity_date).format('DD/MM/YY');
      },
    },
    {
      name: 'Member',
      selector: row => row.user_name,
      sortable: true,
    },
    {
      name: 'Category',
      selector: row => row.category,
      sortable: true,
    },
    {
      name: 'Activity Type',
      selector: row => row.activity_type_name,
      sortable: true,
    },
    {
      name: 'Activity Name',
      selector: row => row.activity_name,
      sortable: true,
    },
    {
      name: 'Points',
      selector: row => row.credits,
      sortable: true,
      format: (row, index) => {
        return row.status_id === 2 ? (
          row.credits
        ) : (
          <Badge colorScheme="yellow">Pending</Badge>
        );
      },
    },
  ];

  return (
    <Box>
      <Text textAlign={'right'} mb={1}>
        <Button
          bgColor="cyan.600"
          color="white"
          size="xs"
          mr={1}
          onClick={onToggle}
        >
          {isOpen ? 'Hide' : 'Show'} Filter
        </Button>
        <Button
          bgColor="cyan.600"
          color="white"
          size="xs"
          mr={1}
          onClick={() => {
            getClubActivities(2);
            setFilter(defaultFilter);
          }}
        >
          <span>Clear Filter</span>
        </Button>

        {loading ? (
          <Box></Box>
        ) : (
          <Button bgColor="cyan.600" color="white" size="xs" mr={1}>
            <CSVLink
              data={clubActivities}
              headers={headers}
              filename={'Club-Activities.csv'}
              className="btn btn-primary"
            >
              Export CSV
            </CSVLink>
          </Button>
        )}

        {selectedRows.length <= 0 ? (
          <Box></Box>
        ) : (
          <Tooltip label="Delete Activities">
            <IconButton
              colorScheme="red"
              size="xs"
              icon={<FaTrash />}
              onClick={() => {
                handleDelete();
              }}
            />
          </Tooltip>
        )}
      </Text>
      <SlideFade in={isOpen} animateOpacity>
        <SimpleGrid
          columns={[1, 4, 4]}
          spacing={3}
          mb={1}
          hidden={isOpen ? false : true}
        >
          <Select
            placeholder="Select Member"
            value={filter.user_id}
            onChange={e => {
              setFilter({
                ...filter,
                user_id: e.target.value,
              });
            }}
          >
            {clubMembers &&
              clubMembers.map(value => {
                return <option value={value.id}>{value.name}</option>;
              })}
          </Select>
          <Select
            placeholder="Activity Category"
            onChange={e => {
              getActivityTypes(e.target.value);
              setFilter({
                ...filter,
                activity_category_id: e.target.value,
              });
            }}
          >
            {activityCategories &&
              activityCategories.map(value => {
                return <option value={value.id}>{value.name}</option>;
              })}
          </Select>
          <Select
            placeholder="Activity Type"
            value={filter.activity_type_id}
            onChange={e => {
              setFilter({
                ...filter,
                activity_type_id: e.target.value,
              });
            }}
          >
            {activityTypes &&
              activityTypes.map(value => {
                return <option value={value.id}>{value.name}</option>;
              })}
          </Select>
          <Select
            placeholder="Activity Name"
            value={filter.activity_id}
            onChange={e => {
              setFilter({
                ...filter,
                activity_id: e.target.value,
              });
            }}
          >
            {activities &&
              activities.map(value => {
                if (!userActivities.includes(value.name)) {
                  userActivities.push(value.name);
                  return <option value={value.id}>{value.name}</option>;
                }
              })}
          </Select>
          <Input
            type="date"
            value={filter.from_date}
            onChange={e => {
              setFilter({
                ...filter,
                from_date: e.target.value,
              });
            }}
          />
          <Input
            type="date"
            value={filter.to_date}
            onChange={e => {
              setFilter({
                ...filter,
                to_date: e.target.value,
              });
            }}
          />
          <Select
            placeholder="Status"
            value={filter.status_id}
            onChange={e => {
              setFilter({
                ...filter,
                status_id: e.target.value,
              });
            }}
          >
            {options.map(value => {
              return <option value={value.id}>{value.name}</option>;
            })}
          </Select>
        </SimpleGrid>
      </SlideFade>
      <Divider />
      {loading ? (
        <TableLoadingSkeleton />
      ) : (
        <Box>
          <DataTable
            columns={tableColumns}
            data={clubActivities}
            pagination
            selectableRows
            selectableRowsHighlight={true}
            onSelectedRowsChange={handleRowSelected}
          />
        </Box>
      )}
    </Box>
  );
}

export default ClubActivitiesTable;
